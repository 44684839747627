(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("fluent-reveal-effect", [], factory);
	else if(typeof exports === 'object')
		exports["fluent-reveal-effect"] = factory();
	else
		root["fluent-reveal-effect"] = factory();
})(this, () => {
return 